<template>
  <div>
    <v-data-table
      :headers="headers"
      :fixed-header="true"
      :loading="loading"
      :items="items"
      item-key="id"
      class="elevation-1 list__table"
    >
      <template v-slot:item.LicencesAdultes="{ item }">
        <v-data-table
          :headers="headersAdultes"
          :items="item.LicencesAdultes"
          item-key="id"
          hide-default-footer
          class="elevation-1 list__table"
          dense
        >
        </v-data-table>
      </template>

      <template v-slot:item.LicencesJeunes="{ item }">
        <v-data-table
          :headers="headersJeunes"
          :items="item.LicencesJeunes"
          item-key="id"
          hide-default-footer
          class="elevation-1 list__table"
          dense
        >
        </v-data-table>
      </template>

      <template v-slot:item.LicencesFamilles="{ item }">
        <v-data-table
          :headers="headersFamilles"
          :items="item.LicencesFamilles"
          item-key="id"
          hide-default-footer
          class="elevation-1 list__table"
          dense
        >
        </v-data-table>
      </template>

      <template v-slot:item.IndemniteJournalieres="{ item }">
        <v-data-table
          :headers="headersIJ"
          :items="item.IndemniteJournalieres"
          item-key="id"
          hide-default-footer
          class="elevation-1 list__table"
          dense
        >
        </v-data-table>
      </template>
      <template v-slot:item.Assurances="{ item }">
        <v-data-table
          :headers="headersAssurances"
          :items="item.Assurances"
          item-key="id"
          hide-default-footer
          class="elevation-1 list__table"
          dense
        >
        </v-data-table>
      </template>
      <template v-slot:item.OptionsAssurances="{ item }">
        <v-data-table
          :headers="headersOptionsAssurances"
          :items="item.OptionsAssurances"
          item-key="id"
          hide-default-footer
          class="elevation-1 list__table"
          dense
        >
        </v-data-table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Nom structure", value: "NomStructure" },
        { text: "Total licence", value: "TotalLicence" },
        { text: "Adultes", value: "LicencesAdultes" },
        { text: "Jeune", value: "LicencesJeunes" },
        { text: "Familles", value: "LicencesFamilles" },
        { text: "Assurances", value: "Assurances" },
        { text: "Opt. Assurances", value: "OptionsAssurances" },
        { text: "IJ", value: "IndemniteJournalieres" },
      ],
      headersAdultes: [
        { text: "H", value: "h", sortable: false },
        { text: "F", value: "f", sortable: false },
        { text: "T", value: "t", sortable: false },
      ],
      headersJeunes: [
        { text: "H", value: "h", sortable: false },
        { text: "F", value: "f", sortable: false },
        { text: "T", value: "t", sortable: false },
      ],
      headersFamilles: [
        { text: "Ad", value: "ad", sortable: false },
        { text: "Jeun.", value: "jeu", sortable: false },
        { text: "T", value: "t", sortable: false },
      ],
      headersIJ: [
        { text: "IJ1", value: "ij1", sortable: false },
        { text: "IJ2.", value: "ij2", sortable: false },
        { text: "IJ3", value: "ij3", sortable: false },
      ],
      headersAssurances: [
        { text: "rc", value: "rc", sortable: false },
        { text: "base", value: "base_1", sortable: false },
        { text: "base+", value: "base_2", sortable: false },
        { text: "base++", value: "base_3", sortable: false },
      ],
      headersOptionsAssurances: [
        { text: "Ski", value: "ski", sortable: false },
        { text: "Trail", value: "trail", sortable: false },
        { text: "Slackline", value: "slackline", sortable: false },
        { text: "VTT", value: "vtt", sortable: false },
      ],
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
