var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headers,"fixed-header":true,"loading":_vm.loading,"items":_vm.items,"item-key":"id"},scopedSlots:_vm._u([{key:"item.LicencesAdultes",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headersAdultes,"items":item.LicencesAdultes,"item-key":"id","hide-default-footer":"","dense":""}})]}},{key:"item.LicencesJeunes",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headersJeunes,"items":item.LicencesJeunes,"item-key":"id","hide-default-footer":"","dense":""}})]}},{key:"item.LicencesFamilles",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headersFamilles,"items":item.LicencesFamilles,"item-key":"id","hide-default-footer":"","dense":""}})]}},{key:"item.IndemniteJournalieres",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headersIJ,"items":item.IndemniteJournalieres,"item-key":"id","hide-default-footer":"","dense":""}})]}},{key:"item.Assurances",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headersAssurances,"items":item.Assurances,"item-key":"id","hide-default-footer":"","dense":""}})]}},{key:"item.OptionsAssurances",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"headers":_vm.headersOptionsAssurances,"items":item.OptionsAssurances,"item-key":"id","hide-default-footer":"","dense":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }