<template>
  <div>
    <subBar title="Tableau de synthèse" route_name="gestion_des_structures"></subBar>
    <v-row no-gutters >
      <v-col cols="12" class="px-6 pt-6">

          <ValidateForm @validated="submit" :showFieldObl="false">
              <v-row>
                  <v-col cols="2" lg="3" style="padding-top: 0; padding-bottom: 0">
                      <inputText v-model="search.ID_CodeFederal" rules="min:3" placeholder="Code structure"></inputText>
                  </v-col>

                    <v-col cols="2" lg="6" style="padding-top: 0; padding-bottom: 0">
                      <inputText v-model="search.NomStructure" rules="min:3" placeholder="Nom Structure"></inputText>
                  </v-col>

                  <v-col cols="2" lg="3" style="padding-top: 0; padding-bottom: 0">
                      <selectInput 
                        v-model="search.ID_TypeStructure " 
                        :items="listStructuresType" 
                        item_text="NomTypeStructure" 
                        item_value="id"  
                        placeholder="Type de structure" 
                      />
                  </v-col>
                   <v-col cols="2" lg="3" style="padding-top: 0; padding-bottom: 0">
                      <selectInput 
                        v-model="search.ID_ComiteTerritorial " 
                        :items="listStructuresCT" 
                        item_text="NomStructure" 
                        item_value="id"  
                        placeholder="Structure dans CT" 
                      />
                  </v-col>
                  <v-col cols="2" lg="6" style="padding-top: 0; padding-bottom: 0">
                      <selectInput 
                        v-model="search.ID_Ligue" 
                        :items="listStructuresLigue" 
                        item_text="NomStructure" 
                        item_value="id"  
                        placeholder="Rechercher les structures de la ligue" 
                      />
                  </v-col>

                  <v-col cols="4" lg="3">
                      <a href="" @click.prevent="erraseFilter">Effacer tous les filtres</a>
                  </v-col>

                  <v-col cols="12" class="pb-3" style="padding-top: 0; padding-bottom: 0">
                      <v-btn class="btn btn-primary mr-4" type='submit' :disabled="disabledSearch || !hasCodeFederal">Rechercher</v-btn>
                  </v-col>

                 
              </v-row>
            </validateForm>

      </v-col>
    </v-row>

    <v-row no-gutters="">
            <v-col>
                <structureSyntheseList
                    :headers="headers" 
                    :actions="actions"
                    :items="fake_data"
                    @hasToReload="reloadQuery"
                    :key="refreshListStructure"
                    ref="structureSyntheseList"
                    @viewChange='handleViewChange'
                ></structureSyntheseList>
            </v-col>
        </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import subBar from "@/components/Common/Common__SubBar.vue";
import structureSyntheseList from "@/components/Structure/Structure__SyntheseList.vue"
import inputText from "@/components/utils/input"
import selectInput from "@/components/utils/select"
import ValidateForm from "@/components/utils/form"
// import {sleep} from "@/plugins/utils"

export default {
  components: {subBar, structureSyntheseList , inputText, ValidateForm, selectInput},

  data: () => ({
    mounted: false,
    structures: [], 
    actions: "show",
    headers: [
     { text: "ID", value: "id" },
    ],
    search: {},
    listStructuresType: [],
    listStructuresCT: [],
    listStructuresLigue: [],
    typeResultat: 0,
    ListTypeResultat: [
      {id: 0, Nom: "Information de base", slug: "information_base"},
      {id: 1, Nom: "Adresse siége", slug: "adresse_siege"},
      {id: 2, Nom: "Adresse de correspondance", slug: "adresse_correspondance"},
      {id: 3, Nom: "Adresse d'accueil", slug: "adresse_accueil"}
    ],
    refreshListStructure: 0,
    json_data: [],
    fake_data: [
      {
      NomStructure:'Structure test',
      TotalLicence:124,
      LicencesAdultes: [{
        h:14,
        f:16,
        t:30
      }],
      LicencesJeunes: [{
        h:12,
        f:18,
        t:30
      }],
       LicencesFamilles: [{
        a:20,
        d:18,
        t:38
      }],
      SkiDePiste:20,
      Slackline:2,
      TrailVTT:4
    }
    ]
  }),

  async created() {
    await this.setData();

    this.mounted = true;
    return;
  },

  computed: {

    ...mapGetters("structure", ["currenStructure", "hasCodeFederal"]),
    ...mapGetters("saisons", ["currentSaisonId"]),

    disabledSearch() {
        if(this.search.NomStructure) { return false}
        if(this.search.ID_CodeFederal) { return false}
        if(this.search.ID_TypeStructure ) { return false}
        if(this.search.ID_ComiteTerritorial  ) { return false}
        if(this.search.ID_Ligue ) { return false}

        return true
    }

  },

  methods: {
    ...mapActions("basics", ["getListStructureType", "getListStructureCT", "getListStructureLigue"]),
    ...mapActions("structure", ["searchStructure", "searchStructureExport"]),

    async reloadQuery() {

    },
    handleViewChange(value) {
      alert('vue is now : ' + value)
    },
    erraseFilter() {
      this.search = {};
    },

    changeTypeResult(type) {

      this.headers = []

      if(type == 0) {

         this.headers = [
            { text: "Vue", value: "Vue",  sortable: false },
            { text: "Nom structure", value: "NomStructure" },
            { text: "Total licence", value: "TotalLicence" },
            { text: "Adultes", value: "LicencesAdultes" },
            { text: "Jeune", value: "LicencesJeunes" },
            { text: "Familles", value: "LicencesFamilles" },
            { text: "Ski de piste", value: "SkiDePiste" },
            { text: "Slackline", value: "Slackline" },
            { text: "Trail VTT", value: "TrailVTT" },
          ]


      }

      this.refreshListStructure ++
    },

    async submit () {
        this.$refs.structureSyntheseList.loading = true
        let response = await this.searchStructure( {
            whereClause: this.getWhereClause(),
        })
        this.structures = response
        console.log("STR",this.structures)
        //this.userListKey += 1
        this.$refs.structureSyntheseList.loading = false
        return
    },

    async setData() {
      this.structures = [];
      this.listStructuresType = await this.getListStructureType()
      this.listStructuresCT = await this.getListStructureCT()
      this.listStructuresLigue = await this.getListStructureLigue()
      this.changeTypeResult(this.typeResultat)
      return;
    },

    getWhereClause() {
      let arrayClause = []
            
            // Setup the field restriction
            if(this.search.EST_CompteBloque) {
              arrayClause.push({EST_CompteBloque: {_eq: this.search.EST_CompteBloque } } )
            }else {
              arrayClause.push({EST_CompteBloque: {_eq: false } } )
            }
            
            if(this.search.ID_CodeFederal) {
              arrayClause.push({ID_CodeFederal: {_eq: this.search.ID_CodeFederal } } )
            }

            if(this.search.NomStructure) {
              arrayClause.push({NomStructure: {_ilike: "%"+this.search.NomStructure.toUpperCase()+"%" } } )
            }
            if(this.search.ID_TypeStructure ) {
              arrayClause.push({ID_TypeStructure: { _eq: this.search.ID_TypeStructure  } } )
            }

            if(this.search.ID_ComiteTerritorial  ) {
              arrayClause.push({ID_ComiteTerritorial  : { _eq: this.search.ID_ComiteTerritorial } } )
            }

            if(this.search.ID_Ligue ) {
              arrayClause.push({ID_Ligue : { _eq: this.search.ID_Ligue  } } )
            }

            let where = { 
              _and: arrayClause
            }

            // End --  Setup the field restriction
        
            return where
        },

        async exportSearch () {
            this.$refs.structureSyntheseList.loading = true
            this.json_data = []
            let response = await this.searchStructureExport( {
              whereClause: this.getWhereClause(),
              ID_Saison: this.currentSaisonId
            })
            response.forEach(item => {
                let csvData = {
                    'Code structure': item.id ,
                    'Type de structure': item.LIST_StructureType.NomTypeStructure ,
                    'Nom de la structure': item.NomStructure,
                    'Complément de nom de la structure': item.NomStructure.NomStructureComplement  ,
                    'Adresse - accueil': item.ADR_Adresse_Accueil ? item.ADR_Adresse_Accueil.Adresse1 : "" ,
                    'Adresse complément - accueil': item.ADR_Adresse_Accueil ? item.ADR_Adresse_Accueil.Adresse2 : "",
                    'Code postal - accueil': item.ADR_Adresse_Accueil ? item.ADR_Adresse_Accueil.CodePostal : "",
                    'Ville - accueil': item.ADR_Adresse_Accueil ? item.ADR_Adresse_Accueil.Ville : "",
                    'Email de contact du club': item.INFO_Email,
                    'Téléphone': item.INFO_Telephone,
                    'Site WEB': item.DOC_Urls[0]?.Url, 
                    'Nom prénom du correspondant': item.NomCorrespondant? `${item.NomCorrespondant.CT_Nom} ${item.NomCorrespondant.CT_Prenom}` : '', 
                    'Adresse - correspondance': item.ADR_Adresse_Correspondance ? item.ADR_Adresse_Correspondance.Adresse1 : "" ,
                    'Adresse complément - correspondance': item.ADR_Adresse_Correspondance ? item.ADR_Adresse_Correspondance.Adresse2 : "",
                    'Code postal - correspondance': item.ADR_Adresse_Correspondance ? item.ADR_Adresse_Correspondance.CodePostal : "" ,
                    'Ville - correspondance': item.ADR_Adresse_Correspondance ? item.ADR_Adresse_Correspondance.Ville : "",
                    'Nom prénom du président': item.president[0]?.UTI_Utilisateur ? `${item.president[0]?.UTI_Utilisateur.CT_Nom} ${item.president[0]?.UTI_Utilisateur.CT_Prenom}` : '', 
                    'Email du président': item.president[0]?.UTI_Utilisateur.CT_Email, 
                    'Président licence':  item.president[0]?.UTI_Utilisateur.UTI_Licences.length ? 'OUI': 'NON', 
                    'Nom prénom du secrétaire': item.secretaire[0]?.UTI_Utilisateur ? `${item.secretaire[0]?.UTI_Utilisateur.CT_Nom} ${item.secretaire[0]?.UTI_Utilisateur.CT_Prenom}`: '', 
                    'Email du secrétaire': item.secretaire[0]?.UTI_Utilisateur.CT_Email, 
                    'Secrétaire licence':  item.secretaire[0]?.UTI_Utilisateur.UTI_Licences.length ? 'OUI': 'NON', 
                    'Nom prénom du trésorier':item.tresorier[0]?.UTI_Utilisateur ? `${item.tresorier[0]?.UTI_Utilisateur.CT_Nom} ${item.tresorier[0]?.UTI_Utilisateur.CT_Prenom}` : '', 
                    'Email du trésorier': item.tresorier[0]?.UTI_Utilisateur.CT_Email, 
                    'Trésorier licence': item.tresorier[0]?.UTI_Utilisateur.UTI_Licences.length ? 'OUI': 'NON', 
                    'Affiliation valide pour la saison en cours ?': item.STR_Affiliations.length ? 'OUI': 'NON', 
                    'Accueil public handicapé ?': item.STR_Affiliations[0]?.CLUB_EST_AcceuillantHandicape ? 'OUI': 'NON', 
                    'Age minimum': item.STR_Affiliations[0]?.CLUB_AcceuillantMineurAge, 
                    'Nombre de licenciés dans la structure pour la saison en cours': item.all.aggregate.count, 
                }
                this.json_data.push(csvData)
            })
            setTimeout(() => {                
                this.$refs.csvBtn.$el.click()
                this.$refs.structureSyntheseList.loading = false
            }, 100);
          },

  },
};
</script>

<style>
.v-text-field__details {
  display: none;
}
.v-input__slot {
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
