<template>
    <div class="table__structure_list">
        <v-data-table
            :class="{'filters-opened': areFiltersOpened}"
            :headers="headers"
            :fixed-header="true"
            :loading="loading"
            :items="items"
            item-key="id"
            :footer-props="{
            'items-per-page-options': perPageOption
            }"
            class="elevation-1 list__table" 
        >
            <template v-slot:item.StructureType="{ item  }">
            
               {{getTypeStructure(item)}}

            </template>
            <template v-slot:item.Affiliation="{ item }">
                
               {{getAffiliationStructure(item)}}

            </template>
            <template v-slot:item.Z_DateModification="{ item }">
                
               {{$moment(item.Z_DateModification).format('DD/MM/YYYY')}}

            </template>
            <template v-slot:item.actions="{ item }">
            
                <router-link :to="{ name: 'single_structure__presentation', params: { id: item.id }}">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                           <v-btn
                                class="mx-1"
                                fab
                                x-small
                                color="blue"
                                v-on="on"
                            >
                                <v-icon :color="'white'">mdi-open-in-new</v-icon>
                            </v-btn>
                        </template>
                        <span>Voir la structure</span>
                    </v-tooltip>
                </router-link>

            </template>
        </v-data-table>
        
    </div>
    
</template>

<script>

import {mapGetters} from "vuex"
export default {

    data: () => ({
        loading: false,
        perPageOption : [30,60,90, -1],
    }),

    computed: {
        ...mapGetters('saisons', ['selectedSaison'])
    },

    methods: {
        getTypeStructure(item) {
            if(item.LIST_StructureType.NomTypeStructure) {
                return item.LIST_StructureType.NomTypeStructure
            }
            return "-" 
        },

        getAffiliationStructure(item) {
            if(item.STR_Affiliations) {
                let affiliations = item.STR_Affiliations
               let currentAffiliation = affiliations.find(aff => aff.ID_Saison == this.selectedSaison.id)
               if(!currentAffiliation) {
                   return "NA"
               }
               if(currentAffiliation.ID_EtapeAffiliation == 3) {
                   return 'Aff.'
               }

            }
            return "NA" 
        }
    },

    props: {
        items: {
            type: Array
        },

        headers: {
            type: Array
        },
        areFiltersOpened: {
            type: Boolean,
            default: false
        }
    }
}

</script>

<style lang="scss">

.list__table .v-data-table-header{
    background: rgba(230, 230, 230, 1) ;
}
th {
      background: lightgrey !important;
   }

.table__structure_list {

   .list__table .v-data-table__wrapper{
       height: calc(100vh - 420px) ;
   }
   .list__table.filters-opened .v-data-table__wrapper{
       height: calc(100vh - 500px) ;
   }
   
   &.buttonLine {
      .list__table .v-data-table__wrapper{
       height: calc(100vh - 480px) ;
      }
   }

   &.membresFederaux {
      .list__table .v-data-table__wrapper{
       height: calc(100vh - 310px) ;
      }
   }

   
}


/* .listSearch {
    height: calc(100vh - 370px);
    overflow: hidden;
    overflow-y: scroll;
} */
.filters-opened {
    height: calc(100vh - 440px);
}
</style>