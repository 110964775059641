var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__structure_list"},[_c('v-data-table',{staticClass:"elevation-1 list__table",class:{'filters-opened': _vm.areFiltersOpened},attrs:{"headers":_vm.headers,"fixed-header":true,"loading":_vm.loading,"items":_vm.items,"item-key":"id","footer-props":{
        'items-per-page-options': _vm.perPageOption
        }},scopedSlots:_vm._u([{key:"item.StructureType",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeStructure(item))+" ")]}},{key:"item.Affiliation",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAffiliationStructure(item))+" ")]}},{key:"item.Z_DateModification",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.Z_DateModification).format('DD/MM/YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'single_structure__presentation', params: { id: item.id }}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"blue"}},on),[_c('v-icon',{attrs:{"color":'white'}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir la structure")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }