<template>
  <div>
    <v-row no-gutters>
      <SubBar :title="subBarTitle" route_name="Home" />
    </v-row>
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 40%;">
            <v-col cols="6">
              <dashboardCard
                module_name="gestion_des_structures__search"
                title_card="Chercher une structure"
                auth
              />
            </v-col>
            <v-col cols="6" v-if="canAccess">
              <dashboardCard
                module_name="gestion_des_affiliations__demandes_primo_affiliations"
                title_card="Gestion des affiliations"
                auth
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import dashboardCard from "@/components/Common/DashboardCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SubBar,
    dashboardCard,
  },
  computed: {
    ...mapGetters("user", ["can"]),

    canAccess() {
      return this.can(this.gestionAffiliation);
    },
  },
  data() {
    return {
      subBarTitle: "Gestion des structures",
      gestionAffiliation:
        "gestion_des_affiliations__demandes_primo_affiliations",
    };
  },
  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },
  mounted() {},
  async created() {
    await this.setAppTitle({ title: "Gestion de la structure" });
  },
  watch: {
    $route(to, from) {
      console.log(`this.routee===>>`, this.$route);
    },
  },
};
</script>
