<template>
  <ul class="d-flex flex-column justify-space-between text-center pl-0">
    <liNav
      v-if="isAdministrator"
      title="Administration"
      module_name="single_administration_information_structure"
      admin
    />
    <liNav
      title="Présentation"
      module_name="single_structure__presentation"
      auth
    />
    <liNav
      title="Organigramme"
      module_name="single_structure__organigramme"
      auth
    />
    <liNav :title="actualitesName" module_name="single_structure__news" auth />
    <liNav
      title="Domiciliation"
      module_name="single_structure__domiciliation"
      auth
    />
    <liNav
      title="Nom correspondant"
      module_name="single_structure__nom_correspondant"
      auth
    />
    <liNav
      title="Accueil et contact"
      module_name="single_structure__accueil_et_contact"
      auth
    />
    <liNav
      title="Documents officiels"
      module_name="single_structure__documents_officiels"
      auth
    />
    <liNav title="Finances" module_name="single_structure__finances" auth />
    <liNav
      title="Gestion des salariés"
      module_name="single_structure__salaries"
      auth
    />
    <liNav
      title="Réseaux sociaux"
      module_name="single_structure__reseaux_sociaux"
      auth
    />
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import liNav from '@/components/Common/Navigation__item';

export default {
  data: () => ({
    structure: [],
  }),

  components: { liNav },

  computed: {
    ...mapGetters('user', ['getRole']),
    actualitesName() {
      return 'Actualités';
    },
    isAdministrator() {
      let role = this.getRole.SlugRole;
      if (
        role === 'super_administrateur' ||
        role === 'administrateur_vie_territoriale'
      )
        return true;
      return false;
    },
  },
  props: {
    str_id: {
      type: Number,
    },
  },
};
</script>
