<template>
  <div>
    <subBar
      title="Gestion des structures"
      route_name="gestion_des_structures"
    ></subBar>
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span v-if="panel === 0">Masquer les filtres</span>
              <span v-else>Afficher les filtres</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f0f0f0">
              <ValidateForm @validated="submit" :showFieldObl="false">
                <v-row class="pt-5">
                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <inputText
                      v-model="search.NomStructure"
                      rules="min:3"
                      placeholder="Nom Structure"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <inputText
                      v-model="search.ID_CodeFederal"
                      rules="min:3"
                      placeholder="Code structure"
                    ></inputText>
                  </v-col>
                  <!-- <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.Number_Departement"
                      placeholder="N° Département"
                    ></inputText>
                  </v-col> -->
                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <selectInput
                      v-model="search.Name_Departement"
                      :items="departementList"
                      item_text="NomCodeDepartement"
                      item_value="id"
                      placeholder="Département"
                    />
                  </v-col>

                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <selectInput
                      v-model="search.ID_TypeStructure"
                      :items="listStructuresType"
                      item_text="NomTypeStructure"
                      item_value="id"
                      placeholder="Type de structure"
                    />
                  </v-col>

                  <v-col
                    v-if="isFFME || isLigue"
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <selectInput
                      v-model="search.ID_ComiteTerritorial"
                      :items="listStructuresCT"
                      item_text="NomStructure"
                      item_value="id"
                      placeholder="Comité territorial"
                    />
                  </v-col>
                  <v-col
                    v-if="isFFME"
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <selectInput
                      v-model="search.ID_Ligue"
                      :items="listStructuresLigue"
                      item_text="NomStructure"
                      item_value="id"
                      placeholder="Ligue"
                    />
                  </v-col>

                  <v-col
                    v-if="isFFME && hasCodeFederal"
                    cols="2"
                    lg="2"
                    class="py-0"
                  >
                    <v-row>
                      <v-col class="py-0">
                        <v-switch
                          v-model="search.EST_CompteBloque"
                          label="Structure bloquée"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" lg="4" class="py-0">
                    <selectInput
                      placeholder="Type de handicap accueilli"
                      v-model="search.Disabilities"
                      :items="listDisabilities"
                      item_text="name"
                      item_value="id"
                      multiple
                    ></selectInput>
                  </v-col>

                  <v-col
                    cols="4"
                    lg="4"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <selectInput
                      v-model="typeResultat"
                      :items="ListTypeResultat"
                      @onChange="changeTypeResult"
                      item_text="Nom"
                      item_value="id"
                      placeholder="Type de resultats"
                    />
                  </v-col>

                  <v-col cols="4" lg="3">
                    <!-- <a href="" @click.prevent="erraseFilter"
                      >Effacer tous les filtres liste</a
                    > -->
                    <v-btn
                      @click.prevent="erraseFilter()"
                      outlined
                      color="indigo"
                      >Effacer tous les filtres</v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="pb-3"
                    style="padding-top: 0; padding-bottom: 0;"
                  >
                    <v-btn
                      class="btn btn-primary mr-4"
                      type="submit"
                      :disabled="disabledSearch"
                      >Rechercher</v-btn
                    >
                    <v-btn
                      class="btn btn-primary"
                      :loading="loading"
                      @click="exportSearch"
                      :disabled="!!!structures.length"
                      >Exporter la recherche</v-btn
                    >
                    <JsonExcel
                      class="d-none"
                      ref="csvBtn"
                      :data="json_data"
                      :fields="json_fields"
                      name="filename.xls"
                      >Exporter</JsonExcel
                    >
                  </v-col>
                </v-row>
              </ValidateForm>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row no-gutters="">
      <v-col v-if="!showStats">
        <structureList
          :areFiltersOpened="panel === 0"
          :headers="headers"
          :actions="actions"
          :items="structures"
          @hasToReload="reloadQuery"
          :key="refreshListStructure"
          ref="structureList"
        ></structureList>
      </v-col>
      <v-col v-if="showStats">
        <structureSyntheseList
          :headers="headers"
          :actions="actions"
          :items="statsStructure"
          ref="structureList"
        ></structureSyntheseList>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import subBar from "@/components/Common/Common__SubBar.vue";
import structureList from "@/components/Structure/Structure__List.vue";
import inputText from "@/components/utils/input";
import selectInput from "@/components/utils/select";
import ValidateForm from "@/components/utils/form";
import JsonExcel from "vue-json-excel";
import structureSyntheseList from "@/components/Structure/Structure__SyntheseList.vue";
// import {sleep} from "@/plugins/utils"

export default {
  components: {
    subBar,
    structureList,
    inputText,
    ValidateForm,
    selectInput,
    JsonExcel,
    structureSyntheseList,
  },

  data: () => ({
    mounted: false,
    loading: false,
    departementList: [],
    showStats: false,
    structures: [],
    allStructureList: [],
    listDisabilities: [],
    actions: "show",
    headers: [],
    search: {},
    allListStructuresType: [],
    listStructuresType: [],
    listStructuresCT: [],
    listStructuresLigue: [],
    typeResultat: 0,
    ListTypeResultat: [
      { id: 0, Nom: "Information de base", slug: "information_base" },
      { id: 1, Nom: "Adresse siége", slug: "adresse_siege" },
      {
        id: 2,
        Nom: "Adresse de correspondance",
        slug: "adresse_correspondance",
      },
      { id: 3, Nom: "Adresse d'accueil", slug: "adresse_accueil" },
      { id: 4, Nom: "Statistiques", slug: "statistiques" },
    ],
    refreshListStructure: 0,
    json_data: [],
    json_fields: {
      "Code structure": "code",
      "Type de structure": "structure_type",
      "Nom de la structure": "structure_name",
      "Complément de nom de la structure": "structure_complement",
      "Adresse - accueil": "adr_accueil",
      "Adresse complément - accueil": "adr_accueil_complement",
      "Code postal - accueil": "adr_accueil_cedex",
      "Ville - accueil": "adr_accueil_city",
      "Email de contact du club": "club_email",
      Téléphone: "phone",
      "Site WEB": "website",
      "Nom prénom du correspondant": "correspondant_name",
      "Adresse - correspondance": "adr_correspondant",
      "Adresse complément - correspondance": "adr_correspondant_complement",
      "Code postal - correspondance": "adr_correspondant_cedex",
      "Ville - correspondance": "adr_correspondant_city",
      "Nom prénom du président": "president_name",
      "Email du président": "president_email",
      "Président licence": "president_licence",
      "Nom prénom du secrétaire": "secretaire_name",
      "Email du secrétaire": "secretaire_email",
      "Secrétaire licence": "secretaire_licence",
      "Nom prénom du trésorier": "tresorier_name",
      "Email du trésorier": "tresorier_email",
      "Trésorier licence": "tresorier_licence",
      "Affiliation valide pour la saison en cours ?": "affiliation",
      "Accueil public handicapé ?": "handicaped",
      "Age minimum": "min_age",
      "Nombre de licenciés dans la structure pour la saison en cours":
        "nb_licencier",
    },
    panel: 0,
    fake_data: [
      {
        NomStructure: "Structure test",
        TotalLicence: 124,
        LicencesAdultes: [
          {
            h: 14,
            f: 16,
            t: 30,
          },
        ],
        LicencesJeunes: [
          {
            h: 12,
            f: 18,
            t: 30,
          },
        ],
        LicencesFamilles: [
          {
            a: 20,
            d: 18,
            t: 38,
          },
        ],
        SkiDePiste: 20,
        Slackline: 2,
        TrailVTT: 4,
      },
    ],
  }),

  async created() {
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "hasCodeFederal"]),
    ...mapGetters("saisons", ["currentSaisonId"]),

    disabledSearch() {
      if (
        this.search.NomStructure ||
        this.search.ID_CodeFederal ||
        this.search.ID_TypeStructure ||
        this.search.ID_ComiteTerritorial ||
        this.search.ID_Ligue ||
        this.search.EST_CompteBloque ||
        this.search.Number_Departement ||
        this.search.Name_Departement ||
        this.search.Disabilities
      ) {
        return false;
      } else return true;
    },

    isFFME() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "service_federal_ffme";
    },

    isCT() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "comite_territorial";
    },
    isLigue() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "ligue";
    },

    statsStructure() {
      let stats = [];
      if (this.structures && this.structures.length) {
        this.structures.map((str) => {
          let exist = false;
          this.statistiques.map((stat) => {
            if (str.id == stat.ID_Structure) {
              stats.push(stat);
              exist = true;
            }
          });
          if (!exist) {
            stats.push(this.emptyStats(str.id));
          }
        });
      } else {
        stats = this.statistiques;
      }
      return stats;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListStructureType",
      "getListStructureCT",
      "getListStructureLigue",
      "getListStructureCTByLigue",
      "getDepartementsList",
      "getListDisabilities",
    ]),
    ...mapActions("structure", [
      "searchStructure",
      "searchStructureExport",
      "getStatistiquesStructures",
      "getStructureList",
    ]),

    async reloadQuery() {},
    emptyStats(structure_id) {
      return {
        ID_Structure: structure_id,
        NomStructure: this.allStructureList.find((s) => s.id == structure_id)
          .NomStructure,
        TotalLicence: "-",
        LicencesAdultes: [
          {
            h: "-",
            f: "-",
            t: "-",
          },
        ],
        LicencesJeunes: [
          {
            h: "-",
            f: "-",
            t: "-",
          },
        ],
        LicencesFamilles: [
          {
            ad: "-",
            jeu: "-",
            t: "-",
          },
        ],
        IndemniteJournalieres: [
          {
            ij1: "-",
            ij2: "-",
            ij3: "-",
          },
        ],
        Assurances: [
          {
            rc: "-",
            base_1: "-",
            base_2: "-",
            base_3: "-",
          },
        ],
        OptionsAssurances: [
          {
            ski: "-",
            slackline: "-",
            vtt: "-",
            trail: "-",
          },
        ],
      };
    },

    erraseFilter() {
      this.search = {};
      this.typeResultat = null;
    },

    changeTypeResult(type) {
      this.headers = [];
      this.showStats = false;
      if (type == 0) {
        this.headers = [
          { text: "Structure", value: "NomStructure" },
          { text: "Code", value: "ID_CodeFederal" },
          { text: "Type", value: "StructureType" },
          { text: "Etat affiliation", value: "Affiliation" },
          { text: "Date de modification", value: "Z_DateModification" },
          { text: "Actions", value: "actions" },
        ];
      } else if (type == "1") {
        this.headers = [
          { text: "Nom", value: "NomStructure" },
          { text: "Adresse 1", value: "ADR_Adresse.Adresse1" },
          { text: "Adresse 2", value: "ADR_Adresse.Adresse2" },
          { text: "Code postal", value: "ADR_Adresse.CodePostal" },
          { text: "Ville", value: "ADR_Adresse.Ville" },
        ];
      } else if (type == "2") {
        this.headers = [
          { text: "Nom", value: "NomStructure" },
          { text: "Adresse 1", value: "ADR_Adresse_Correspondance.Adresse1" },
          { text: "Adresse 2", value: "ADR_Adresse_Correspondance.Adresse2" },
          {
            text: "Code postal",
            value: "ADR_Adresse_Correspondance.CodePostal",
          },
          { text: "Ville", value: "ADR_Adresse_Correspondance.Ville" },
        ];
      } else if (type == "3") {
        this.headers = [
          { text: "Nom", value: "NomStructure" },
          { text: "Adresse 1", value: "ADR_Adresse_Accueil.Adresse1" },
          { text: "Adresse 2", value: "ADR_Adresse_Accueil.Adresse2" },
          { text: "Code postal", value: "ADR_Adresse_Accueil.CodePostal" },
          { text: "Ville", value: "ADR_Adresse_Accueil.Ville" },
        ];
      } else if (type == "4") {
        this.showStats = true;
      }

      this.refreshListStructure++;
    },

    async submit() {
      this.$refs.structureList.loading = true;
      let response = await this.searchStructure({
        whereClause: this.getWhereClause(),
      });
      this.structures = response;
      let haveDepartement = this.structures?.filter(
        (structure) => structure.ID_Departement === 1
      );
      //this.userListKey += 1
      this.$refs.structureList.loading = false;
      return;
    },

    filterStats(structures) {
      let data = [];
      structures.forEach((str) => {
        data.push({
          ID_Structure: str.ID_Structure,
          NomStructure: this.allStructureList.find(
            (s) => s.id == str.ID_Structure
          )?.NomStructure,
          TotalLicence: str.COUNT_Licences,
          LicencesAdultes: [
            {
              h: str.COUNT_LicencesAdulteHomme,
              f: str.COUNT_LicencesAdulteFemme,
              t: str.COUNT_LicencesAdulte,
            },
          ],
          LicencesJeunes: [
            {
              h: str.COUNT_LicencesJeuneHomme,
              f: str.COUNT_LicencesJeuneFemme,
              t: str.COUNT_LicencesJeune,
            },
          ],
          LicencesFamilles: [
            {
              ad: str.COUNT_LicencesFamilleAdulte,
              jeu: str.COUNT_LicencesFamilleJeune,
              t: str.COUNT_LicencesFamille,
            },
          ],
          IndemniteJournalieres: [
            {
              ij1: str.COUNT_IJ1,
              ij2: str.COUNT_IJ2,
              ij3: str.COUNT_IJ3,
            },
          ],
          Assurances: [
            {
              rc: str.COUNT_AssuranceRC,
              base_1: str.COUNT_AssuranceBase,
              base_2: str.COUNT_AssuranceBasePlus,
              base_3: str.COUNT_AssuranceBasePlusPlus,
            },
          ],
          OptionsAssurances: [
            {
              ski: str.COUNT_AssuranceSki,
              slackline: str.COUNT_AssuranceSlackline,
              vtt: str.COUNT_AssuranceVTT,
              trail: str.COUNT_AssuranceTrail,
            },
          ],
        });
      });
      console.log(data);
      return data;
    },

    async setData() {
      this.structures = [];

      this.allListStructuresType = await this.getListStructureType();

      this.allStructureList = await this.getStructureList({ whereClause: {} });

      this.statistiques = this.filterStats(
        await this.getStatistiquesStructures({
          ID_Saison: this.currentSaisonId,
        })
      );

      this.listDisabilities = await this.getListDisabilities();

      const departementListTemp = await this.getDepartementsList();
      this.departementList = departementListTemp?.map((depart) => ({
        ...depart,
        NomCodeDepartement: `${depart.CodeDepartement}- ${depart.NomDepartement}`,
      }));

      let strType = [];
      strType.push({ NomTypeStructure: "Club", id: -1 });
      strType.push({
        NomTypeStructure: "Comité territorial",
        id: this.allListStructuresType.find(
          (str) => str.SlugTypeStructure == "comite_territorial"
        ).id,
      });
      strType.push({
        NomTypeStructure: "Etablissement affilié",
        id: this.allListStructuresType.find(
          (str) => str.SlugTypeStructure == "club_etablissement_affilie"
        )?.id,
      });
      strType.push({
        NomTypeStructure: "Ligue",
        id: this.allListStructuresType.find(
          (str) => str.SlugTypeStructure == "ligue"
        )?.id,
      });
      strType.push({
        NomTypeStructure: "Membre associé",
        id: this.allListStructuresType.find(
          (str) => str.SlugTypeStructure == "club_membre_associe"
        )?.id,
      });
      strType.push({
        NomTypeStructure: "Service fédéral FFME",
        id: this.allListStructuresType.find(
          (str) => str.SlugTypeStructure == "service_federal_ffme"
        )?.id,
      });
      // strType.push({NomTypeStructure: 'Structure externe', id: this.listStructuresType.find(str => str.SlugTypeStructure == "service_federal_ffme").id})
      this.listStructuresType = strType;
      console.log(strType);

      if (this.isLigue) {
        this.listStructuresCT = await this.getListStructureCTByLigue({
          ID_Ligue: this.currentStructure.ID_Structure,
        });
      } else if (this.isFFME) {
        this.listStructuresCT = await this.getListStructureCT();
      }
      console.log(
        "this.currentStructure.ID_Structure===>>",
        this.currentStructure.ID_Structure
      );
      console.log("this.list==>>", this.listStructuresCT);
      this.listStructuresLigue = await this.getListStructureLigue();
      this.changeTypeResult(this.typeResultat);
      return;
    },

    getWhereClause() {
      let arrayClause = [];

      // Setup the field restriction
      if (this.search.EST_CompteBloque) {
        arrayClause.push({
          EST_CompteBloque: { _eq: this.search.EST_CompteBloque },
        });
      } /*else {
        arrayClause.push({ EST_CompteBloque: { _eq: false } });
      }*/

      if (this.search.ID_CodeFederal) {
        arrayClause.push({
          ID_CodeFederal: { _eq: this.search.ID_CodeFederal },
        });
      }
      if (this.search.Number_Departement) {
        arrayClause.push({
          ID_Departement: { _eq: parseInt(this.search.Number_Departement) },
        });
      }
      if (this.search.Name_Departement) {
        arrayClause.push({
          ID_Departement: { _eq: parseInt(this.search.Name_Departement) },
        });
      }

      if (this.search.NomStructure) {
        arrayClause.push({
          NomStructure: {
            _ilike: "%" + this.search.NomStructure.toUpperCase() + "%",
          },
        });
      }

      if (this.search.ID_TypeStructure) {
        if (this.search.ID_TypeStructure == -1) {
          let clubIds = [];
          clubIds.push();
          clubIds.push({
            ID_TypeStructure: {
              _eq: this.allListStructuresType.find(
                (str) => str.SlugTypeStructure == "club_association"
              )?.id,
            },
          });
          clubIds.push({
            ID_TypeStructure: {
              _eq: this.allListStructuresType.find(
                (str) => str.SlugTypeStructure == "club_section_association"
              )?.id,
            },
          });
          arrayClause.push({ _or: clubIds });
        } else {
          arrayClause.push({
            ID_TypeStructure: { _eq: this.search.ID_TypeStructure },
          });
        }
      }

      if (this.isCT) {
        arrayClause.push({
          ID_ComiteTerritorial: { _eq: this.currentStructure.ID_Structure },
        });
      }

      if (this.isLigue) {
        console.log("this.currentStructure :>> ", this.currentStructure);
        arrayClause.push({
          ID_Ligue: { _eq: this.currentStructure.ID_Structure },
        });

        if (this.search.ID_ComiteTerritorial) {
          arrayClause.push({
            ID_ComiteTerritorial: { _eq: this.search.ID_ComiteTerritorial },
          });
        }
      }

      if (this.isFFME) {
        if (this.search.ID_ComiteTerritorial) {
          arrayClause.push({
            ID_ComiteTerritorial: { _eq: this.search.ID_ComiteTerritorial },
          });
        }
        if (this.search.ID_Ligue) {
          arrayClause.push({ ID_Ligue: { _eq: this.search.ID_Ligue } });
        }
      }

      if (this.search.Disabilities) {
        let disabilities = [];

        this.search.Disabilities.forEach((idDisability) => {
          disabilities.push({
            STR_Structure_Disabilities: {
              id_disability: {
                _eq: idDisability
              }
            },
          });
        });
        arrayClause.push({ _or: disabilities });
      }

      let where = {
        _and: arrayClause,
      };

      // End --  Setup the field restriction

      return where;
    },

    async exportSearch() {
      this.$refs.structureList.loading = true;
      this.loading = true;
      this.json_data = [];
      let response = await this.searchStructureExport({
        whereClause: this.getWhereClause(),
        ID_Saison: this.currentSaisonId,
      });
      response.forEach((item) => {
        let csvData = {
          code: item.id,
          structure_type: item.LIST_StructureType.NomTypeStructure,
          structure_name: item.NomStructure,
          structure_complement: item.NomStructure.NomStructureComplement,
          adr_accueil: item.ADR_Adresse_Accueil
            ? item.ADR_Adresse_Accueil.Adresse1
            : "",
          adr_accueil_complement: item.ADR_Adresse_Accueil
            ? item.ADR_Adresse_Accueil.Adresse2
            : "",
          adr_accueil_cedex: item.ADR_Adresse_Accueil
            ? item.ADR_Adresse_Accueil.CodePostal
            : "",
          adr_accueil_city: item.ADR_Adresse_Accueil
            ? item.ADR_Adresse_Accueil.Ville
            : "",
          club_email: item.INFO_Email,
          phone: item.INFO_Telephone,
          website: item.DOC_Urls[0]?.Url,
          correspondant_name: item.NomCorrespondant
            ? `${item.NomCorrespondant.CT_Nom} ${item.NomCorrespondant.CT_Prenom}`
            : "",
          adr_correspondant: item.ADR_Adresse_Correspondance
            ? item.ADR_Adresse_Correspondance.Adresse1
            : "",
          adr_correspondant_complement: item.ADR_Adresse_Correspondance
            ? item.ADR_Adresse_Correspondance.Adresse2
            : "",
          adr_correspondant_cedex: item.ADR_Adresse_Correspondance
            ? item.ADR_Adresse_Correspondance.CodePostal
            : "",
          adr_correspondant_city: item.ADR_Adresse_Correspondance
            ? item.ADR_Adresse_Correspondance.Ville
            : "",
          president_name: item.president[0]?.UTI_Utilisateur
            ? `${item.president[0]?.UTI_Utilisateur.CT_Nom} ${item.president[0]?.UTI_Utilisateur.CT_Prenom}`
            : "",
          president_email: item.president[0]?.UTI_Utilisateur.CT_Email,
          president_licence: item.president[0]?.UTI_Utilisateur.UTI_Licences
            .length
            ? "OUI"
            : "NON",
          secretaire_name: item.secretaire[0]?.UTI_Utilisateur
            ? `${item.secretaire[0]?.UTI_Utilisateur.CT_Nom} ${item.secretaire[0]?.UTI_Utilisateur.CT_Prenom}`
            : "",
          secretaire_email: item.secretaire[0]?.UTI_Utilisateur.CT_Email,
          secretaire_licence: item.secretaire[0]?.UTI_Utilisateur.UTI_Licences
            .length
            ? "OUI"
            : "NON",
          tresorier_name: item.tresorier[0]?.UTI_Utilisateur
            ? `${item.tresorier[0]?.UTI_Utilisateur.CT_Nom} ${item.tresorier[0]?.UTI_Utilisateur.CT_Prenom}`
            : "",
          tresorier_email: item.tresorier[0]?.UTI_Utilisateur.CT_Email,
          tresorier_licence: item.tresorier[0]?.UTI_Utilisateur.UTI_Licences
            .length
            ? "OUI"
            : "NON",
          affiliation: item.STR_Affiliations.length ? "OUI" : "NON",
          handicaped: item.STR_Affiliations[0]?.CLUB_EST_AcceuillantHandicape
            ? "OUI"
            : "NON",
          min_age: item.STR_Affiliations[0]?.CLUB_AcceuillantMineurAge,
          nb_licencier: item.all.aggregate.count,
        };
        this.json_data.push(csvData);
      });
      setTimeout(() => {
        this.$refs.csvBtn.$el.click();
      }, 100);
      this.$refs.structureList.loading = false;
      this.loading = false;
    },
  },
};
</script>

<style>
.v-text-field__details {
  display: none;
}
.v-input__slot {
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
.ffme-form__select {
  .v-input__slot {
    height: auto;
  }
}
</style>
