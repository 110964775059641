<template>
  <div>
    <subBar
      :title="structureName"
      route_name="gestion_des_structures__search"
    ></subBar>

    <v-row v-if="!mounted">
      <v-col cols="6"> En cours de chargement </v-col>
    </v-row>
    <v-row v-else no-gutters class="informationsdemonclub__layout">
      <v-col cols="12" color="blue" sm="4" lg="3" class="scrollable">
        <v-row class="informationsdemonclub__sidebar" no-gutters>
          <v-col
            cols="12"
            class="informationsdemonclub__sidebar-avatar avatar_container py-4"
          >
            <div class="avatar-logo">
              <img :src="logoStructure" class="logo" />
            </div>
          </v-col>

          <v-col cols="12" class="informationsdemonclub__sidebar-navigation">
            <NavigationSingle :str_id="structure.id" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8" lg="9" class="informationsdemonclub__content">
        <v-row
          class="informationsdemonclub__content-content-view h-100"
          no-gutters
        >
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import subBar from "@/components/Common/Common__SubBar.vue";
import NavigationSingle from "@/components/GestionDeLaStructure/InformationStructure/NavigationSingle.vue";
import router from "@/router";
// import imge from "../../assets/images/FFME_LOGO_INSTIT_PRINCIPAL_FOND_CLAIR_RVB.png";
// import {sleep} from "@/plugins/utils"

export default {
  components: { subBar, NavigationSingle },

  data: () => ({
    data: [],
    mounted: false,
    structure: [],
    avatarUrl: "",
  }),

  async created() {
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison", "currentSaisonId"]),

    structureName() {
      return this.structure.NomStructure;
    },

    logoStructure() {
      let logo = this.structure.DOC_Documents.find(
        (doc) =>
          doc.LIST_DocumentType &&
          doc.LIST_DocumentType.SlugTypeDocument == "avatar_structure"
      );
      if (logo) {
        return logo.CheminDocument;
      }
      return require("@/assets/images/default_avatar.jpg");
    },
  },

  methods: {
    ...mapActions("admin", ["getStructure"]),

    async setData() {
      let regex = new RegExp("^[0-9]*$");

      if (!regex.test(this.$route.params.id)) {
        return router.push({ name: "Home" });
      }

      // Les choses commencent ici pour toi @Alban
      var id = parseInt(this.$route.params.id);
      await this.getStructure({
        ID_Structure: id,
        ID_Saison: parseInt(this.currentSaisonId),
      }).then((data) => {
        if (data) {
          this.structure = data;
        } else {
          router.push({ name: "Home" });
        }
      });
    },
  },
};
</script>

<style>
.avatar_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-text-field__details {
  display: none;
}
.v-input__slot {
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
.logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /*,
   min-width: 140px; */
}
.avatar-logo {
  height: 140px;
  min-width: 140px;
  /* background-color: black; */
  background-size: contain;
  width: 140px;
}
</style>
